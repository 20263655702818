/* global Sentry, siteSettings */

/**
 * @brief A copy of utils.js/checkCookie.
 * TODO: reorganize head-sync.js by moving DFP stuff to dfp.js in order to be
 * able to move head-sync.js to the top of the <head> and to be able to use
 * its functions as part of a global objects from all other JS.
 */
function checkCookie(name, value) {
  if (name === undefined) { return false; }

  const valReg = new RegExp(`(?:^|;)(?:\\s)*${name}=(.*?)(?:;|$)`);
  const match = document.cookie.match(valReg);

  if (match) {
    if (value !== undefined) {
      return match[1] === value.toString();
    }
    return match[1] !== '' ? match[1] : false;
  }
  return false;
}

/**
 * @brief Checks if localStorage is supported and available. The main goal is
 * to identify and filter out errors that come from Safari 10- in Private mode.
 * Safari 10 and below in Private mode have the quota for both localStorage and
 * sessionStorage set to 0, which means they're effectively unusable. The issue
 * is fixed in Safari 11 where the storages in Private mode reside in memory.
 * Meanwhile I don't think we should do anything about that behavior: the user
 * DID set it to prevent sites from writing to localStorage as well. So we'll
 * just identify and ignore such cases as false positives.
 *
 * TODO: move to utils?
 *
 * @return Boolean
 */
function localStorageAvailable() {
  const storage = window.localStorage;
  try {
    const name = '_test_';
    storage.setItem(name, '1');
    storage.removeItem(name);
  } catch (e) {
    return storage && !(e instanceof DOMException && (
      e.code === 22 || e.code === 1014 || e.name === 'QuotaExceededError' ||
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED'
    ));
  }
  return true;
}

/**
 * @brief Checks if an IP address falls in a set o IP ranges
 *
 * @param [String] ip - an IP address to check
 * @param [Array] ipRanges - an array of [ipStart, ipEnd] ranges or single IPs
 * @return true, if an IP falls in any of the specified ranges
 */
function checkIpRanges(ip, ipRanges) {
  function ipToNumber(ipAddr) {
    return ipAddr.split('.')
      .reduce(
        (prev, segment, i) => prev + (Number(segment) << 8 * (4 - i - 1)),
        0,
      );
  }

  const ranges = typeof (ipRanges) === 'string' ? [ipRanges] : ipRanges;
  const ipNumeric = ipToNumber(ip);

  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];

    // Single IP address
    if (typeof (range) === 'string' && ip === range) { return true; }

    const startIp = ipToNumber(range[0]);
    const endIp = ipToNumber(range[1]);

    if (ipNumeric >= startIp && ipNumeric <= endIp) { return true; }
  }

  return false;
}

const ignoreErrors = [
  // https://ivelum.slack.com/archives/C27L2HXCG/p1526646938000219
  /Object doesn\'t support property or method \'engn\'/,
  // Brightcove \(VideoJS\) error
  /The play\(\) request was interrupted by a call to pause\(\)/,
  // Also Brightcove code
  /'document.getElementsByTagName\('video'\)\[0\].webkitExitFullScreen'/,
  /The operation was aborted/,
  /An attempt was made to use an object that is not, or is no longer, usable/,
  // Chrome browser error:
  /__gCrWeb\.autofill\.extractForms/,
  // Chrome browser error:
  // https://github.com/WICG/ResizeObserver/issues/38
  /ResizeObserver loop limit exceeded/,
  // Firefox-iOS errors
  /__firefox__\./,
  // Facebook browser
  /_AutofillCallbackHandler/,
  // Amazon Ads
  /amazon-adsystem\.com/,
  // Lytics Bot
  /FDIC2010SS\.js/,
  // Third party scripts errors, mostly from Ad scripts
  /s3\.amazonaws\.com/,
  /platform\.twitter\.com/,
  /youtube\.com/,
  /facebook\.com/,
  /tpc\.googlesyndication\.com/,
  /acdn\.adnxs\.com/,
  /scribd\.com/,
  /Can\'t find variable: mobiGetClick/,
  /document\.getElementById\(\'rsstable\'\)\.src/,
  /Can\'t find variable: loadHomepageTiles/,
  /PAPADDINGXXPADDINGPADDINGXX/,
  /anv_pl_def/,
  /__show__deepen is not defined/,
  /DomNode has not been set for this SimpleScriptable/,
  /Can\'t find variable: __scanAndKill/,
  /document\.getElementById\(\'sdppromo\'\)\.getElementsByTagName/,
  /document\.getElementById\(\'og_head\'\)\.style/,
  /window\.ViewLostFocus is not a function/,
  /window\.DWEventToLiveView is not a function/,
  /Unable to get property \'cmpGlobal\'/,
  /fire_omniture_event is not defined/,
  /Can\'t find variable: myGloFrameList/,
  /global\.CONST\.CAMPAIGN_ERRORS/,
  /pym is not defined/,
  /cygnus/,
  /Ruby_ExtractLoginFormData/,
  /jwplayer/,
  /\'textarea\[name\\x3d'message'\]/,
  /Formstack\.GoogleAnalytics/,
  /Can\'t find variable: Formstack/,
  /Can\'t find variable: pktAnnotationHighlighter/,
  /Object doesn\'t support property or method \'SetMaximized\'/,
  /Can\'t find variable: google/,
  /mobicip_add_event_listeners/,
  /Can\'t find variable: MyAppGetHTMLElementsAtPoint/,
  /Can\'t find variable: MyAppGetLinkHREFAtPoint/,
  /vid_mate_check is not defined/,
  /Cannot read property \'closingEls\' of undefined/,
  /document\.getElementById\(\'wid_banner\'\)\.style/,
  /document\.getElementById\(\'wid_topAd\'\)\.style/,
  /document\.getElementById\(\'wid_adTop\'\)\.style/,
  /document\.getElementById\(\'ads_top\'\)\.style/,
  /document\.getElementById\(\'top-ad\'\)\.style/,
  /document\.getElementById\(\'mojivaiframediv\'\)\.style/,
  /document\.getElementById\(\'adSpace\'\)\.style/,
  /document\.getElementById\(\'aframe0\'\)\.style/,
  /document\.getElementById\(\'ctl00_AdTopBanner_adDiv\'\)\.style/,
  /document\.getElementById\(\'q-footer\'\)\.style/,
  /document\.getElementById\(\'mapid\'\)\.style/,
  /document\.getElementById\(\'bannerad\'\)\.style/,
  /cloak39527/,
  /_isMatchingDomain/,
  /window\.top1\.realvu_boost/,
  // Pubwise's DataClone errors, mostly Linux-specific
  /pbjs\.enableAnalytics/,
  // Trying to block the allegedly spammy error POLICEONE-BROWSER-DBF which
  // flooded our tracker on Apr 8
  /^undefined$/,
  /^Error: undefined$/,
  // eslint-disable-next-line max-len
  /null is not an object \(evaluating 'document.head.querySelector\("meta\[name='supported-color-schemes'\]"\).content'\)/,
  // Various browser extensions
  // Evernote
  /There is no clipping info for given tab$/,
  // Firefox extensions that keep refs to non-existing objects
  /can\'t access dead object/,
  // Bing
  /bingLanguageDetectScriptResponse/,
  // https://github.com/getsentry/sentry-javascript/issues/3440
  /Object Not Found Matching Id/,
];


/**
 * @brief Searches for stuff in the stack trace
 *
 * @param [Array|String] stack - the stack trace
 * @param [Object] options
 *    options.filename - searching in the file name
 *    options.func - searching in the function name
 *    options.mode - 'and' if all searches should succeed (default: 'or')
 * @return boolean
 */
function searchStack(stack, options, event) {
  let debugStr = '';
  if (typeof stack === 'string') {
    debugStr += 'type: string;';
    let isFileFound = false;
    if (options.filename) {
      isFileFound = stack.indexOf(`/${options.filename}`) !== -1;
      debugStr += `file /${options.filename}: ${isFileFound};`;
    }
    let isFuncFound = false;
    if (options.func) {
      isFuncFound = stack.indexOf(options.func) !== -1;
      debugStr += `function /${options.func}: ${isFuncFound};`;
    }

    if (event) {
      // eslint-disable-next-line no-param-reassign
      event.extra.stackDebug = debugStr;
    }

    if (options.mode === 'and') {
      if (isFileFound && isFuncFound) { return true; }
    } else if (isFileFound || isFuncFound) { return true; }
  } else if (stack && typeof stack === 'object' && stack.length) {
    // ^ null is "object". welp
    debugStr += 'type: array;';
    for (let i = 0; i < stack.length; i++) {
      let isFileFound = false;
      if (stack[i].filename && options.filename) {
        isFileFound = stack[i].filename.indexOf(options.filename) !== -1;
        debugStr += `file /${options.filename}: ${isFileFound};`;
      }
      let isFuncFound = false;
      if (stack[i].function && options.func) {
        isFuncFound = stack[i].function.indexOf(options.func) !== -1;
        debugStr += `function /${options.func}: ${isFuncFound};`;
      }

      if (event) {
        // eslint-disable-next-line no-param-reassign
        event.extra.stackDebug = debugStr;
      }

      if (options.mode === 'and') {
        if (isFileFound && isFuncFound) { return true; }
      } else if (isFileFound || isFuncFound) { return true; }
    }
  } else if (event) {
    // eslint-disable-next-line no-param-reassign
    event.extra.stackDebug = 'empty stack';
    return false;
  }

  if (event) {
    // eslint-disable-next-line no-param-reassign
    event.extra.stackDebug = `${options.filename}, ${options.func}: not found;`;
  }
  return false;
}

/**
 * This function will be fired right before an event will be sent to Sentry.
 * Defining it separately to improve the readability of the call to .init().
 *
 * @param {Object} event - the data of the event being sent to Sentry
 * @param {Object} hint - has two keys: originalException that is, well, the
 * exception that the event is being created from, and syntheticException which
 * is created by Sentry.
 *
 * @return {Object/null} Returns an event object (initial or modified) or null
 *  if we want to prevent sending it to Sentry.
 */
function beforeSend(e, hint) {
  // To make eslint shut up with the no-param-reassign
  const event = e;
  if (!event.tags) { event.tags = {}; }
  // To be able to see if an error has bypassed this handler (see:
  // https://github.com/getsentry/sentry-javascript/issues/2126 )
  event.tags.beforesend_has_run = true;
  event.tags.head_sync_loaded = window.headSyncLoaded;

  event.extra = event.extra ? event.extra : {};
  event.extra.headeragent = event.request && event.request.headers &&
    event.request.headers['User-Agent'];

  let stack;
  try {
    // The event might not be an exception or it might not have a stack trace
    stack = event.exception.values[0].stacktrace.frames;
  } catch (error) {
    // A stack prepared by Sentry could be empty, but there might still be
    // something in the original exception object
    stack = hint.originalException && hint.originalException.stack;
  }
  event.extra.stackTrace = stack;

  // Let's tag errors coming from GTM for easier filtering
  const isGtmInStack = searchStack(stack, {
    filename: 'gtm.js',
  });
  event.tags.gtmJs = isGtmInStack;

  //
  // Browser-based filtering
  // ---------------------------

  // Don't send errors from IE10 and older
  if (/MSIE/.test(window.navigator.userAgent)) {
    return null;
  }
  // Block browsers of specific verstions that spam our Issues tracker
  // Chrome 41 (most likely a Google renderer)
  const chrome = window.navigator.userAgent.match(/Chrome\/(\S+)/);
  if (chrome && parseInt(chrome[1], 10) === 41) {
    return null;
  }
  // Firefox 38 (An old LTS)
  const ff = window.navigator.userAgent.match(/Firefox\/(\S+)/);
  if (ff && parseInt(ff[1], 10) === 38) {
    return null;
  }

  // Don't track errors from "lyticsbot". Sentry doesn't seem to recognize
  // it as a "known crawler", thus the custom filter
  const isLyticsBot = /lyticsbot/.test(window.navigator.userAgent) ||
    // For some rason the new Sentry doesn't filter out the lyticsbot anymore
    // Let's see if the User-Agent check works
    event.request && event.request.headers &&
      event.request.headers['User-Agent'].search('lyticsbot') !== -1;
  event.extra.isLyticsBot = isLyticsBot;

  //
  // UA-based filtering
  // ---------------------------

  if (isLyticsBot) {
    // TODO: remove
    Sentry.captureMessage(
      'lyticsbot successfully got cut! ... take that, bitch!',
    );
    return null;
  }

  //
  // Stack-based filtering
  // ---------------------------

  // Yelp embeds generate dozens of errors. Blocking them.
  // https://www.corrections1.com/evergreen/articles/470910187
  if (searchStack(stack, {
    filename: 'yelpcdn.com',
  })) {
    return null;
  }

  if (searchStack(stack, {
    func: 'safari-extension',
  })) {
    return null;
  }

  if (searchStack(stack, {
    filename: 'amp4ads',
  })) {
    return null;
  }

  //
  // Error-based filtering
  // ---------------------------

  const errorName = hint.originalException ? hint.originalException.name : '';
  const errorMsg = hint.originalException ?
    hint.originalException.message : '';

  // `document.creteEvent()` called with an incorrect context / in a weird
  // environment. Generated mostly by lazysizes and the Comments widget.
  // After rather unsuccessful debugging with the lazysizes author and other
  // users, it's most likely generated in the env when Facebooks opens a link
  // and builds its preview. Since the previews actually do work, we couldn't
  // care less about shitty emulators like that, so blocking the error for Fb's
  // IPs altogether.
  if (/^Illegal invocation/.test(errorMsg)) {
    if (siteSettings.userIp && checkIpRanges(siteSettings.userIp, [
      ['66.220.144.0', '66.220.159.255'],
      ['69.171.224.0', '69.171.255.255'],
      ['173.252.64.0', '173.252.127.255'],
      ['31.13.64.0', '31.13.127.255'],
    ])) {
      return null;
    }

    event.extra.webfeatures = JSON.stringify(window.webFeatures);
    try {
      const evt = window.document.createEvent('CustomEvent');
      event.extra.createEvent_worked = evt;
    } catch (error) {
      event.extra.createEvent_worked = false;
    }
  }

  // The overwhelming majority of such errors come from pages with videos, and
  // it's very likely they have something to do with 3rd party video JS. This
  // doesn't seem to affect our users (just as on LMS sites), and there are a
  // number of cases reported by other devs when they just tend to block such
  // errors altogether.
  // For now we'll be blocking them for pages with videos, so we could maybe
  // track and analyze such errors happening for other reasons.
  if (/AbortError/i.test(errorMsg) &&
    document.querySelector('[data-brightcove]')
  ) {
    return null;
  }

  // For some reason IE sometimes throws this error when we're sending an XHR
  // request in the in-house stack. I've exhausted every obscure possible reason
  // I could think of with no luck, and the error, while scarse, is really
  // annoying, so just blocking it.
  if (/Unexpected call to method or property access/i.test(errorMsg) &&
    searchStack(stack, {
      func: 'statsRequestCheck',
    })
  ) { return null; }

  //
  // Grouping events
  // ---------------------------

  if (/Cannot read property 'undefined' of undefined/i.test(errorMsg) &&
    searchStack(stack, {
      mode: 'and',
      filename: 'lio.js',
      func: 'checkPushIntegrations',
    })
  ) {
    event.fingerprint = [
      'Type: TypeError',
      'Message: Cannot read property \'undefined\' of undefined',
    ];
  }

  // Sentry can acutally help us sort of fix memory leaks in IE11. Upon
  // catching a corresponding error we're asking a user if they are okay
  // with refreshing a page, which prevents a browser from crashing.
  if (/^Out of memory/i.test(errorMsg)) {
    const isIe11 = document.all && !window.atob ||
      window.navigator.msPointerEnabled;
    const isIe11UA = window.navigator.userAgent.search('Trident/7.0') !== -1;
    // eslint-disable-next-line no-alert
    const pageReloaded = window.confirm('We have detected that you\'re using ' +
        'Internet Explorer 11 or a similar browser, ' +
        'which is known to have issues with memory leaks.' +
        ' Your browser is almost out of memory, which ' +
        'can make the site unresponsive. Please reload ' +
        'this page to free memory and fix this.');

    // Just in case an event manages to break out and gets sent
    event.fingerprint = [
      'Type: Error',
      'Message: Out of memory',
    ];
    // The error is still managing to break through; trying to debug if the
    // browser version is identified at all.
    event.tags.isIE11 = isIe11;
    event.tags.isIe11UA = isIe11UA;
    event.tags.userReloadedAPage = pageReloaded;

    if (pageReloaded) {
      document.location.reload();
    } else if (isIe11) {
      // If a user decides not to reload a page and the error does pop up,
      // don't send it to Sentry. At least from IE
      return null;
    }
  }

  // We can't tell what frames ours are trying to access, and these are quite
  // difficult to reproduce, so it's just a pointless flooding into our sentry
  // feed. Blocking them.
  if (
    /Blocked a frame with origin ".*?" from accessing a cross-origin frame./
      .test(errorMsg)
  ) {
    return null;
  }

  // Separating cases when we can see the object frame's address, so we could
  // at least try to investigate
  // eslint-disable-next-line max-len
  if (/Blocked a frame with origin ".*?" from accessing a frame with origin ".*?"./.test(errorMsg)) {
    if (searchStack(stack, {
      func: 'initAutoFill',
    })) {
      return null;
    }

    if (/safeframe\.googlesyndication\.com/.test(errorMsg)) {
      return null;
    }

    // Blocking them otherwise to avoid flooding of our slack channel
    event.fingerprint = [
      'Type: SecurityError',
      'Message: Blocked a frame with origin *',
    ];
  }

  if (/^No error/.test(errorMsg)) {
    event.fingerprint = [
      'Type: Error',
      'Message: No error',
    ];
  }

  if (errorName === 'QuotaExceededError' ||
    errorName === 'NS_ERROR_DOM_QUOTA_REACHED' ||
    /^QuotaExceededError/.test(errorMsg)
  ) {
    // Filtering out Safari 10- in Private mode, see the description for the
    // funciton
    if (!localStorageAvailable()) {
      return null;
    }
    event.fingerprint = [
      'Type: QuotaExceededError',
      'Message: QuotaExceededError',
    ];
    // Let's see if the original message makes things clearer (in case the
    // error breaks through)
    event.extra.message = hint.originalException.message;
  }

  // Other errors, that don't group well
  if (
    /^null is not an object \(evaluating 'item\.obj\[item\.propName\]'\)/
      .test(errorMsg)
  ) {
    event.fingerprint = [
      'Type: TypeError',
      'Message: null is not an object',
    ];
  }

  if (/^Cannot read property 'ima3' from undefined/.test(errorMsg)) {
    event.fingerprint = [
      'Type: TypeError',
      'Message: null is not an object',
    ];
  }

  if (/^Cannot find function superfish in object \[object Object\]/
    .test(errorMsg)
  ) {
    event.fingerprint = [
      'Type: TypeError',
      'Message: Cannot find function superfish in object [object Object]',
    ];
  }

  if (/^"\$" is not defined/.test(errorMsg) ||
    (/^"jQuery" is not defined/.test(errorMsg))
  ) {
    event.fingerprint = [
      'Type: ReferenceError',
      'Message: \"$\" is not defined',
    ];
  }

  // jQuery missing in gtm.js
  if (/Can't find variable: $/i.test(errorMsg) ||
    /$ is not defined/i.test(errorMsg)
  ) {
    if (isGtmInStack) {
      event.fingerprint = [
        'Type: ReferenceError',
        'Message: Can\'t find variable: $',
      ];
    }
  }

  if (/^'renderItemUsingLytcs'/.test(errorMsg)) {
    event.fingerprint = [
      'Type: TypeError',
      'Message: Cannot read property renderItemUsingLytcs of undefined',
    ];
  }

  if (/^Cannot find function push in object \[object Proxy\]/
    .test(errorMsg)
  ) {
    event.fingerprint = [
      'Type: TypeError',
      'Message: Cannot find function push in object [object Proxy]',
    ];
  }

  // Debugging the Owl carousel issue
  if (/annot read property 'chrome' of undefined/.test(errorMsg)) {
    const w = {};
    const windowKeys = Object.keys(window);
    for (let i = 0; i < windowKeys.length; i++) {
      const key = windowKeys[i];
      try {
        if (typeof (window[key]) !== 'function') {
          w[key] = window[key].toString();
        } else {
          w[key] = 'function';
        }
      } catch (error) {
        // do nothing
      }
    }
    event.extra.window = w;
  }

  if (/Sentry.Integrations.Dedupe is not a constructor/.test(errorMsg) ||
    // eslint-disable-next-line max-len
    /undefined is not a constructor (evaluating 'new Sentry.Integrations.Dedupe')/.test(errorMsg)
  ) {
    event.fingerprint = [
      'Type: TypeError',
      'Message: Sentry.Integrations.Dedupe is not a constructor',
    ];
  }

  if (/'DetectDeviceHelper' is undefined/.test(errorMsg) ||
    /DetectDeviceHelper is not defined/.test(errorMsg)
  ) {
    event.fingerprint = [
      'Type: TypeError',
      'Message: DetectDeviceHelper is not defined',
    ];
  }

  // Another way to try and block POLICEONE-BROWSER-DBF that is possibly
  // a spam
  if (/^undefined$/.test(errorMsg)) {
    return null;
  }

  // Some race condition in removing a fallback iframe by Lytics
  if ((/NotFoundError/.test(errorMsg) ||
    /Failed to execute 'removeChild' on 'Node'/.test(errorMsg)) &&
    searchStack(stack, { filename: 'lio.js' }, event)
  ) {
    return null;
  }

  // Some race condition in updating the timer in Brightcove videos
  if (/Failed to execute 'replaceChild'/.test(errorMsg) &&
    searchStack(stack, { func: 'players.brightcove.net' }, event)
  ) {
    return null;
  }

  // Most likely crlf injection or the activity of some broken bot
  if (/%0D/.test(errorMsg) || /\)$/.test(errorMsg)) {
    return null;
  }

  return event;
}

Sentry.onLoad(() => {
  const config = {
    // Not needed if we're using Sentry's lazy loader
    // dsn: siteSettings.sentryUrlFrontEnd,

    environment: siteSettings.sentryEnvironment || undefined,
    release: siteSettings.sentryRelease || undefined,

    integrations: [
      // A set of filters that allow us to not send certain events to Sentry
      // to save the monthly events capacity.
      Sentry.inboundFiltersIntegration({
        // IMPORTANT: Add a pattern for every eligible domain name for scripts
        // that Sentry should SEND errors from. And we also have a whitelist
        // of domains that Sentry should RECIEVE errors from, in Sentry's Web
        // UI (to prevent abusing our account).
        whitelistUrls: [
          /police1\.com/,
          /firerescue1\.com/,
          /ems1\.com/,
          /corrections1\.com/,
          /gov1\.com/,
          /firechief\.com/,
          /policegrantshelp\.com/,
          /firegrantshelp\.com/,
          /emsgrantshelp\.com/,
          /govgrantshelp\.com/,
          /educationgrantshelp\.com/,
          /correctionsonegrants\.com/,
          /praetorian\.netdna-ssl\.com/,
          /lexipol\.com/,
          /efficientgov\.com/,
          /policeone\.com/,
          /correctionsone\.com/,
        ],
        // These 3rd party scripts get fired "from" our domains and thus don't
        // get filtered out with URL filter list.
        blacklistUrls: [
          // McAfee Web Gateway errors; probably from a browser addon that
          // injects its scripts in pages.
          // /mwg-internal/,
        ],
        // These errors won't be sent to Sentry at all. All of them don't have
        // anything to do with our code, and there is no way for us to control
        // the code that produces them. This includes browser extensions,
        // ads, 3rd party widgets, and so on.
        ignoreErrors,
      }),
    ],

    beforeSend,
  };

  // Sometimes a single client would start spamming us with the exact same
  // error for no apparent reason. I still don't have a slightest clue as
  // to why this might be happening. But let's at least try blocking such
  // duplicate errors.
  if (Sentry.dedupeIntegration) {
    config.integrations.push(Sentry.dedupeIntegration());
  }
  Sentry.init(config);

  // Extending sentry metadata
  Sentry.setTags({
    has_adblock: !window.AdBlockDisabled,
    has_googletag: !!window.googletag,
    has_lytics: !!(window.lio || window.liosetup),
    // Previously 'has_lazyload'
    has_lazyads: !!(window.lazyAds && window.lazyAds.ready),
    has_cubUser: !!checkCookie('cubUserToken'),
    // TODO: monitor the errors for a couple of weeks to see if any errors come
    // without Dedupe, and if there are outbursts of errors from a client that
    // doesn't have Dedupe for some reason. Depending on that we might want to
    // block errors in such clients altogether.
    has_dedupe: !!Sentry.dedupeIntegration,
  });
  Sentry.setExtras({
    webfeautres: !!window.webFeatures,
    mediaDevices: !!navigator.mediaDevices,
  });
});

// A debug key for our "Script with a backup" partial (to notify it that this
// script has executed successfully)
window.hasSentryInited = true;
